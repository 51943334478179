import { z } from 'zod'
import { SUPPORTED_LOCALES } from '~/i18n/config'

export function parseUnknownLocale(unknownLocale: unknown) {
  return z.enum(SUPPORTED_LOCALES).safeParse(unknownLocale)
}

export function getLocaleParamFromPathname(pathname: string) {
  const unknownLocale = pathname.split('/').at(1)

  return parseUnknownLocale(unknownLocale)
}
